import React from 'react';

const Home = () => {
  return (
    <div className="home-container">
      <div className='home-img'>
      </div>
    </div>
  );
};

export default Home;
